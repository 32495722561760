/* this file is transformed by vux-loader */
/* eslint-disable */
import _createForOfIteratorHelper from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _toConsumableArray from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.split.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Datetime from 'vux/src/components/datetime';
import PopupPicker from 'vux/src/components/popup-picker';
import XButton from 'vux/src/components/x-button';
import XInput from 'vux/src/components/x-input';
import XTextarea from 'vux/src/components/x-textarea';
import OrgTree from '@/components/OrgTree/OrgTree';
import atitle from '@/components/Approve/atitle';
import { Dialog, Toast } from 'vant'; // -----审批节点

import FixedNode from '@/components/Approve/FixedNode';
import AssignNode from '@/components/Approve/AssignNode';
import OfficersNode from '@/components/Approve/OfficersNode';
import NoAssiginNode from '@/components/Approve/NoAssiginNode';
import copyOffNode from '@/components/Approve/copyOffNode';
import copyNoAssiginNode from '@/components/Approve/copyNoAssiginNode';
import copyFixedNode from '@/components/Approve/copyFixedNode';
import UploadImageRequired from '@/components/UploadImage/UploadImageRequired';
export default {
  name: 'MakeTrip',
  components: {
    XInput: XInput,
    PopupPicker: PopupPicker,
    Datetime: Datetime,
    XTextarea: XTextarea,
    OrgTree: OrgTree,
    XButton: XButton,
    UploadImageRequired: UploadImageRequired,
    atitle: atitle,
    FixedNode: FixedNode,
    AssignNode: AssignNode,
    OfficersNode: OfficersNode,
    NoAssiginNode: NoAssiginNode,
    copyOffNode: copyOffNode,
    copyNoAssiginNode: copyNoAssiginNode,
    copyFixedNode: copyFixedNode
  },
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    if (this.showFlag) {
      next(false);
      this.showFlag = false;
    } else {
      this.$utils.Store.removeItem('kssj');
      this.$utils.Store.removeItem('jssj');
      this.$utils.Store.removeItem('inPlace');
      this.$utils.Store.removeItem('outPlace');
      this.$utils.Store.removeItem('reason');
      this.$utils.Store.removeItem('vehicle');
      this.$utils.Store.removeItem('credit');
      this.$utils.Store.removeItem('stayDays');
      this.$utils.Store.removeItem('stay');
      next();
    }
  },
  data: function data() {
    return {
      actions: this.$api.Base.dev + '/management/insertPhoto',
      applyMan: this.$utils.Store.getItem('userLname'),
      //申请人
      reason: this.$utils.Store.getItem('reason') ? this.$utils.Store.getItem('reason') : '',
      //出差目的
      inPlace: '',
      //出发地
      outPlace: this.$utils.Store.getItem('outPlace') ? this.$utils.Store.getItem('outPlace') : '',
      //目的地
      startDate: '',
      //开始日期
      endDate: '',
      //截止日期
      kssj: this.$utils.Store.getItem('kssj') ? this.$utils.Store.getItem('kssj') : '',
      //开始时间
      jssj: this.$utils.Store.getItem('jssj') ? this.$utils.Store.getItem('jssj') : '',
      //结束时间
      credit: '',
      //预借金额
      vehicleList: [['飞机', '火车', '高铁', '动车', '客车', '汽车', '轮船', '其他']],
      //交通工具数组
      vehicle: this.$utils.Store.getItem('vehicle') ? JSON.parse(this.$utils.Store.getItem('vehicle')) : [],
      //选择的交通工具
      stayList: [{
        name: '是',
        value: '1'
      }, {
        name: '否',
        value: '0'
      }],
      stay: this.$utils.Store.getItem('stay') ? JSON.parse(this.$utils.Store.getItem('stay')) : [],
      stayDays: '',
      remind: true,
      //日程提醒
      henchman: [],
      //同行人数组
      copyerChecked: [],
      //抄送人序号
      henchmanChecked: [],
      //同行人序号
      inputParam: [{
        name: 'att_id',
        value: 0
      }, {
        name: 'base64String',
        value: ''
      }, {
        name: 'typeId',
        value: -1
      }],
      imgList: [],
      //图片列表数组
      limitNum: 5,
      //最多上传图片张数
      bh: '00103',
      //假类编号
      depSerial: this.$utils.Store.getItem('orgId'),
      //企业ID
      depName: localStorage.getItem('orgName'),
      //企业名称
      henchmanString: '',
      //同行人序号--字符串
      imgPath: [],
      //图片地址
      imgIndex: 0,
      //图片数组下标
      choose: this.$utils.Store.getItem('choose') ? this.$utils.Store.getItem('choose') : 0,
      //添加人员类型   1：审批人  2：抄送人  3：同行人
      showFlag: false,
      //是否显示组织架构弹窗
      selectType: 0,
      //组织架构选择类型
      infoChecked: [],
      //
      flag: true,
      //可以提交
      icon: 'icon-jiahao',
      //icon加号
      interval: '',
      //  ----以下是lwt的变量--2020-07-01
      approve: [],
      //要提交的审批节点列表
      allUserList: [],
      //获取过来的审批节点
      process: 0,
      //0简单审批 1条件审批
      userSerial: this.$utils.Store.getItem('userSerial'),
      //人员编号
      approveConditionId: '',
      approveSettingId: '',
      cc: {},
      //抄送人
      haveCC: false,
      //有没有抄送人
      showLevel: '',
      sbmitDis: true,
      //是否可以点击
      approveStatues: false,
      //控制提交状态isDisabled
      chaosongzhuangtai: false //控制提交状态isDisabled

    };
  },
  computed: {
    /* 这个代码真的服了 一个人一个风格 学习了反正就是坑多的一塌糊涂 */
    isDisabled: function isDisabled() {
      if (this.sbmitDis && this.kssj != '' && this.jssj != '' && this.inPlace != '' && this.outPlace != '' && this.reason != '' && this.vehicle.length > 0) {
        /**/
        return false;
      } else {
        return true;
      }
    } // infoChecked() {
    //   if (this.selectType == 9999 && this.henchmanChecked.length > 0) {
    //     return this.henchmanChecked
    //   }
    // },

  },
  methods: {
    /* 2021/11/15 优化增加删除不影响业务*/
    getShenpiRenshu: function getShenpiRenshu(aa) {
      /* 审批人数关联提交状态子组建传值 */
      if (aa > 0) {
        this.approveStatues = true;
      } else if (aa == 0) {
        this.approveStatues = false;
      }
    },

    /* 2021/11/15 优化增加删除不影响业务*/
    gechaosongrenshu: function gechaosongrenshu(aa) {
      /*抄送人数关联提交状态子组建传值 */
      if (aa > 0) {
        this.chaosongzhuangtai = true;
      } else if (aa == 0) {
        this.chaosongzhuangtai = false;
      }
    },

    /** function 获取服务器时间---今日日期
     *   wzx
     *   2019-01-11
     **/
    getSysDate: function getSysDate() {
      var _this = this;

      this.$utils.Tools.loadShow();
      this.$api.Sys.getSystemDate(this.$params.mode).then(function (response) {
        // this.$utils.Tools.loadHide();
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this.$code.success) {
          Toast(msg);
        } else {
          _this.startDate = data.sysDate.substring(0, 10);
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },

    /** function 获取出发地
     *   wzx
     *   2019-01-05
     **/
    getInPlace: function getInPlace(val) {
      this.inPlace = val;
      this.$utils.Store.setItem('inPlace', val);
    },

    /** function 获取目的地
     *   wzx
     *   2019-01-05
     **/
    getOutPlace: function getOutPlace(val) {
      this.outPlace = val;
      this.$utils.Store.setItem('outPlace', val);
    },

    /** function 获取出差开始时间
     *   wzx
     *   2019-01-05
     **/
    changeStartTime: function changeStartTime(val) {
      this.kssj = val;
      this.$utils.Store.setItem('kssj', val);
      this.startDate = val;
      /*if(this.jssj != '' && this.kssj>this.jssj){
      this.$utils.Tools.toastShow("出行日期不能大于返回日期");
      setTimeout(()=>{
          this.kssj = '';
      },2000)
      }*/
    },

    /** function 获取出差结束时间
     *   wzx
     *   2019-01-05
     **/
    changeEndTime: function changeEndTime(val) {
      this.jssj = val;
      this.$utils.Store.setItem('jssj', val);
      this.endDate = val;
      /*if(this.kssj != '' && this.jssj<this.kssj){
      this.$utils.Tools.toastShow("返回日期不能小于出行日期");
      setTimeout(()=>{
          this.jssj = '';
      },2000)
      }*/
    },

    /** function 获取交通工具
     *   wzx
     *   2019-01-05
     **/
    changeVehicle: function changeVehicle(val) {
      this.vehicle = val;
      this.$utils.Store.setItem('vehicle', JSON.stringify(val));
    },

    /** function 获取是否住宿
     *   wzx
     *   2019-01-05
     **/
    changeStay: function changeStay(val) {
      this.stay = val;
      this.$utils.Store.setItem('stay', JSON.stringify(val));
    },
    getStayDays: function getStayDays(val) {
      var reg = /(^[0-9]+$)|(^[0-9]+[.]{1}[0-9]{0,1}$)/;

      if (reg.test(val)) {
        this.stayDays = val;
        this.$utils.Store.setItem('stayDays', val);
      } else {
        this.stayDays = '';
        Toast('必须为数字格式，且天数小数位最多1位');
      }
    },

    /** function 获取住宿天数
     *   wzx
     *   2019-01-05
     **/
    changeStayDays: function changeStayDays(e) {
      var _this2 = this;

      // this.stayDays = val;
      if (e != '') {
        setTimeout(function () {
          _this2.stayDays = e.match(/^\d*(\.?\d{0,1})/g)[0] || 0; // this.$utils.Store.setItem('stayDays',this.stayDays);
        }, 1);
      }
    },

    /** function 获取预借金额
     *   wzx
     *   2019-01-05
     **/
    getCredit: function getCredit() {},

    /** function 获取预借金额
     *   wzx
     *   2019-01-05
     **/
    changeCredit: function changeCredit(val) {
      if (val != '') {
        var reg = /(^[0-9]+$)|(^[0-9]+[.]{1}[0-9]{0,2}$)/;

        if (reg.test(val)) {
          this.credit = val;
          this.$utils.Store.setItem('credit', val);
        } else {
          this.credit = '';
          Toast('必须为数字格式，且金额小数位最多2位');
        }
        /*setTimeout(() => {
        this.credit = (val.match(/^\d{0,5}(\.\d{0,2})?/g)[0])
        }, 0)*/

      }
    },

    /** function 获取出差事由
     *   wzx
     *   2019-01-05
     **/
    getReason: function getReason(val) {
      this.reason = val;
      this.$utils.Store.setItem('reason', val);
    },

    /** function 是否开启日程提醒
     *   wzx
     *   2019-01-05
     **/
    remindChange: function remindChange(val) {
      this.remind = val;
    },
    onChange: function onChange() {},
    onShow: function onShow() {},
    onHide: function onHide() {},

    /** function 获取从组织同行人数组
     *   wzx
     *   2018-12-26
     * */
    selectUsers: function selectUsers(val) {
      if (val.length > 10) {
        Toast('设定人数不可以多于10人');
        return;
      }

      var MyUserSerial = this.$utils.Store.getItem('userSerial');

      if (val[0].userSerial == MyUserSerial) {
        Dialog({
          title: '提示',
          message: '同行人不可选本人',
          width: '70%',
          closeOnPopstate: true
        });
        return;
      }

      if (this.selectType == 1) {
        if (this.approver.length > 0) {
          var result = this.approver.some(function (item) {
            if (item.userSerial == val[0].userSerial) {
              return true;
            }
          });

          if (result) {
            Toast('同行人不可重复');
          } else {
            var _this$approver;

            (_this$approver = this.approver).push.apply(_this$approver, _toConsumableArray(val));
          }
        } else {
          var _this$approver2;

          (_this$approver2 = this.approver).push.apply(_this$approver2, _toConsumableArray(val));
        }
      } else if (this.selectType == 9999) {
        if (this.customer.length > 0) {
          var _this$customer;

          this.customer = [];

          (_this$customer = this.customer).push.apply(_this$customer, _toConsumableArray(val));

          var hash = {};
          /*es6数组去重*/

          var newArr = this.customer.reduceRight(function (item, next) {
            hash[next.userSerial] ? '' : hash[next.userSerial] = true && item.push(next);
            return item;
          }, []);
          this.customer = newArr;

          var _iterator = _createForOfIteratorHelper(this.customer),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var b = _step.value;
              this.customerChecked.push(b.userSerial);
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
        } else {
          var _this$customer2;

          (_this$customer2 = this.customer).push.apply(_this$customer2, _toConsumableArray(val));

          var _iterator2 = _createForOfIteratorHelper(this.customer),
              _step2;

          try {
            for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
              var _b = _step2.value;
              this.customerChecked.push(_b.userSerial);
            }
          } catch (err) {
            _iterator2.e(err);
          } finally {
            _iterator2.f();
          }
        }
      } else if (this.selectType == 9998) {
        if (this.henchman.length > 0) {
          var _this$henchman;

          this.henchman = [];

          (_this$henchman = this.henchman).push.apply(_this$henchman, _toConsumableArray(val));

          var _hash = {};
          /*es6数组去重*/

          var _newArr = this.henchman.reduceRight(function (item, next) {
            _hash[next.userSerial] ? '' : _hash[next.userSerial] = true && item.push(next);
            return item;
          }, []);

          this.henchman = _newArr;

          var _iterator3 = _createForOfIteratorHelper(this.henchman),
              _step3;

          try {
            for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
              var _b2 = _step3.value;
              this.henchmanChecked.push(_b2.userSerial);
            }
          } catch (err) {
            _iterator3.e(err);
          } finally {
            _iterator3.f();
          }
        } else {
          var _this$henchman2;

          (_this$henchman2 = this.henchman).push.apply(_this$henchman2, _toConsumableArray(val));

          var _iterator4 = _createForOfIteratorHelper(this.henchman),
              _step4;

          try {
            for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
              var _b3 = _step4.value;
              this.henchmanChecked.push(_b3.userSerial);
            }
          } catch (err) {
            _iterator4.e(err);
          } finally {
            _iterator4.f();
          }
        }
      }
    },

    /** function 获取同行人数组
     *   wzx
     *   2019-01-05
     * */
    getHenchman: function getHenchman() {
      if (this.$utils.Store.getItem('henchman')) {
        var _this$henchman3;

        var apprData = JSON.parse(this.$utils.Store.getItem('henchman'));

        (_this$henchman3 = this.henchman).push.apply(_this$henchman3, _toConsumableArray(apprData));

        var _iterator5 = _createForOfIteratorHelper(apprData),
            _step5;

        try {
          for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
            var i = _step5.value;
            this.henchmanChecked.push(i.userSerial);
          }
        } catch (err) {
          _iterator5.e(err);
        } finally {
          _iterator5.f();
        }
      }
    },

    /** function 删除同行人
     *   wzx
     *   2019-01-05
     * */
    deleteHenchman: function deleteHenchman(index) {
      this.henchman.splice(index, 1);
    },

    /** function 添加同行人
     *   wzx
     *   2019-01-05
     * */
    chooseHenchman: function chooseHenchman() {
      //copyType----1:请假 2:出差 3:外出 4:加班 5:调班6:补出勤  choose---1:审批人 2：抄送人  3：同行人
      this.infoChecked = [];
      this.selectType = 9998;
      this.showFlag = true;

      var _iterator6 = _createForOfIteratorHelper(this.henchman),
          _step6;

      try {
        for (_iterator6.s(); !(_step6 = _iterator6.n()).done;) {
          var b = _step6.value;
          this.infoChecked.push(b.userSerial);
        }
      } catch (err) {
        _iterator6.e(err);
      } finally {
        _iterator6.f();
      }
    },

    /** function 遍历数组是否少
     *   wzx
     *  2020-0706
     **/
    ergArray: function ergArray(list) {
      if (!list) {
        return true;
      }

      var uindex = list.findIndex(function (item) {
        return !item.userSerial[0];
      });
      return uindex != -1;
    },

    /** function 提交出差申请
     *   wzx
     *   2019-01-05
     * */
    submitClick: function submitClick() {
      var _this3 = this;

      var reg = /(^[0-9]+$)|(^[0-9]+[.]{1}[0-9]{0,2}$)/;
      var regs = /(^[0-9]+$)|(^[0-9]+[.]{1}[0-9]{0,1}$)/;

      if (this.kssj > this.jssj) {
        Toast('出行日期不能大于返回日期');
      } else if (this.credit && !reg.test(this.credit)) {
        Toast('必须为数字格式，且金额小数位最多2位');
      } else if (this.stayDays && !regs.test(this.stayDays)) {
        Toast('必须为数字格式，且天数小数位最多1位');
      } else if (this.ergArray(this.approve)) {
        Toast('未选择审批人');
        return;
      } else if (this.haveCC && !this.cc.userSerial[0]) {
        Toast('未选择抄送人');
        return;
      } else {
        this.sbmitDis = false;
        this.imgPath = [];
        var myhenchman = JSON.stringify(this.henchmanChecked).replace(/\[|]|"/g, '');
        this.henchmanString = myhenchman;
        var outVehicle = JSON.stringify(this.vehicle).replace(/\[|]|"/g, '');

        var _iterator7 = _createForOfIteratorHelper(this.imgList),
            _step7;

        try {
          for (_iterator7.s(); !(_step7 = _iterator7.n()).done;) {
            var imgs = _step7.value;
            this.imgPath.push(imgs.serverId);
          }
        } catch (err) {
          _iterator7.e(err);
        } finally {
          _iterator7.f();
        }

        var imgPath = JSON.stringify(this.imgPath).replace(/\[|]|"/g, '');
        this.$utils.Tools.loadShow();
        var businessEntity = {
          bh: '00103',
          businessPurposes: this.reason,
          outMoney: this.credit,
          imgPath: imgPath,
          kssj: this.kssj,
          jssj: this.jssj,
          inPlace: this.inPlace,
          outPlace: this.outPlace,
          outVehicle: outVehicle,
          processId: '',
          qjlx: 1,
          userSerial: this.$utils.Store.getItem('userSerial'),
          accommodation: this.stay[0],
          accommodationDays: this.stayDays,
          travelPeople: this.henchmanString
        };
        var params = {
          businessEntity: businessEntity,
          approveCode: 1,
          approveSettingId: this.approveSettingId,
          approveConditionId: this.approveConditionId,
          approveCodeSub: '00103',
          petitioner: this.$utils.Store.getItem('userSerial'),
          cc: [this.cc],
          showLevel: this.showLevel,
          approve: this.approve
        };
        this.$api.Appr.apply(params).then(function (response) {
          _this3.$utils.Tools.loadHide();

          var code = response.code,
              msg = response.msg;
          _this3.sbmitDis = true;

          if (code == '625' || code == '626') {
            _this3.$utils.Tools.loadHide();

            Dialog.alert({
              title: '提示',
              message: msg,
              messageAlign: 'left',
              width: '70%',
              closeOnPopstate: true
            }).then(function () {
              // on close
              _this3.$router.go(0);
            });
            return;
          }

          if (+code !== _this3.$code.success) {
            Toast(msg);
          } else {
            /*let state = encodeURI(data.xh+",1");
            this.$router.replace({name:'MyApplyTrip', params: {state: state}})*/
            Toast('提交成功');

            _this3.$router.go(-1);
          }
        }).catch(function () {
          _this3.$utils.Tools.httpError();
        });
      }
    },

    /** 函数function 设定当前页面
     *  wzx
     *  2019.01.09
     **/
    pushHistory: function pushHistory() {
      var state = {
        title: 'title',
        url: ''
      };
      window.history.pushState(state, state.title, state.url);
    },
    setDtConfig: function setDtConfig() {
      var _this4 = this;

      var localUrl = window.location.href.split('#')[0];
      this.$api.DingTalk.getDtConfig(localUrl).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this4.$code.success) {
          _this4.$utils.Tools.toastShow(msg);
        } else {
          _this4.$utils.DingTalk.getConfig(data);

          setTimeout(function () {
            _this4.onDtPosition();
          }, 500);
        }
      }).catch(function () {
        _this4.$utils.Tools.httpError();
      });
    },
    onDtPosition: function onDtPosition() {
      var _this5 = this;

      this.$utils.Tools.loadShow();
      var that = this;
      that.$utils.DingTalk.dtGetLocationCity('allmap', 14, false, function (resp) {
        _this5.$utils.Tools.loadHide();

        if (resp.flag === true) {
          that.inPlace = resp.city;
        } else {
          that.$utils.Tools.toastShow(_this5.$t('location.failed'));
        }
      });
    },
    onPosition: function onPosition() {
      var _this6 = this;

      this.$utils.Tools.loadShow();
      var localUrl = window.location.href.split('#')[0];
      var that = this;
      this.$api.Sys.getConfig(localUrl, this.$params.mode).then(function (response) {
        var code = response.code,
            msg = response.msg;

        if (+code !== that.$code.success) {
          that.$utils.Tools.toastShow(msg);
        } else {
          // that.$utils.WeiXin.wxConfig(data); 上传图片功能中已调用微信接口，此处可不调用
          that.$utils.Tools.loadShow('正在定位');
          that.$utils.WeiXin.wxGetLocationCity('allmap', 14, false, function (resp) {
            if (resp.flag === true) {
              that.inPlace = resp.city;
            } else {
              that.$utils.Tools.toastShow(_this6.$t('location.failed'));
            }

            that.$utils.Tools.loadHide();
          });
        }
      }).catch(function () {
        that.$utils.Tools.httpError();
      });
    },

    /** function 当前提交审批节点中是否有对应的节点
     *   lwt
     *   2020-07-03
     * */
    inspectObj: function inspectObj(sub) {
      var index = 0;
      var flag = this.approve.some(function (items, i) {
        if (items.level == sub.level) {
          index = i;
          return true;
        }
      });
      return {
        index: index,
        flag: flag
      };
    },

    /** function 获取子集的节点对象
     *   lwt
     *   2020-07-03
     * */
    getSubUserObj: function getSubUserObj(appr) {
      if (appr.cc && appr.cc == 1) {
        this.cc = appr;
        return;
      }

      if (this.approve.length == 0) {
        this.approve.push(appr);
      } else {
        var insUser = this.inspectObj(appr);

        if (insUser.flag) {
          //如果有重复的
          this.approve[insUser.index] = appr;
        } else {
          //没有重复的
          this.approve.push(appr);
        }
      }
    },

    /** function 获取可审批的节点
     *   lwt
     *   2020-07-04
     * */
    getNodeList: function getNodeList() {
      var _this7 = this;

      this.$utils.Tools.loadShow();
      this.allUserList = [];
      this.haveCC = false;
      this.cc = {};
      var params = {
        approveCode: '1',
        //出差
        subCode: '00103',
        startTime: this.kssj,
        //开始时间
        endTime: this.jssj,
        //结束时间
        userSerial: this.userSerial //人员序列

      };
      this.$api.Appr.getApplicants(params).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (code == '602') {
          _this7.$utils.Tools.loadHide();

          Dialog({
            title: '提示',
            message: msg,
            messageAlign: 'left',
            width: '70%',
            closeOnPopstate: true
          });
          return;
        }

        if (+code !== _this7.$code.success) {
          _this7.$utils.Tools.loadHide();

          _this7.$utils.Tools.toastShow(msg);
        } else {
          _this7.$utils.Tools.loadHide();

          _this7.showLevel = data.showLevel;

          if (data.showLevel == '1') {
            _this7.allUserList = data.approve;
          } else {
            for (var i = 0; i < data.approve.length; i++) {
              if (data.approve[i].level < data.showLevel) {
                (function () {
                  var userItem = {};
                  var user = [];
                  data.approve[i].user.some(function (sub) {
                    user.push(sub.userSerial);
                  });
                  userItem.level = data.approve[i].level;
                  userItem.way = data.approve[i].levelWay;
                  userItem.levelId = data.approve[i].levelId;
                  userItem.userSerial = user;

                  _this7.approve.push(userItem);
                })();
              } else {
                _this7.allUserList.push(data.approve[i]);
              }
            }
          }

          if (data.ccSet) {
            _this7.haveCC = true; //有没有抄送人

            _this7.allUserList[_this7.allUserList.length] = data.ccSet; //抄送
          }

          _this7.approveConditionId = data.approveConditionId;
          _this7.approveSettingId = data.approveSettingId;
          _this7.process = data.process;
        }
      }).catch(function () {
        _this7.$utils.Tools.httpError();
      });
    }
  },
  created: function created() {
    this.$utils.Tools.loadShow();
    this.$utils.Store.removeItem('oldVal');
    this.getSysDate();
    this.getNodeList(); // this.onPosition()
  },
  mounted: function mounted() {
    var _this8 = this;

    this.$utils.Tools.getEnv(function (res) {
      if (res === 0 || res === 1) {
        _this8.onPosition();

        _this8.interval = setInterval(function () {
          _this8.onPosition();
        }, 60000);
      } else {
        _this8.setDtConfig();

        _this8.interval = setInterval(function () {
          // this.setConfig();
          _this8.onDtPosition();
        }, 60000);
      }
    }); // this.pushHistory();
    // window.onpopstate = () => {
    //     this.$router.push('/Application')  //输入要返回的上一级路由地址
    // }
  },
  destroyed: function destroyed() {
    this.$utils.Store.removeItem('oldVal');
    clearInterval(this.interval);
    this.$utils.Tools.loadHide();
  }
};